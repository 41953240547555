import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../auth/authContext';

const PrivateRoute = () => {
  const { user, roles = [] } = useAuth(); 

  if (!user) {
    return <Navigate to="/login" />;
  }

  // Check for admin routes
  if (window.location.pathname.startsWith('/admin') && !roles.includes('Admin')) {
    return <Navigate to="/activities" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
