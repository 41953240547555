// src/RoutesContainer.jsx
import React, { Suspense, lazy, useState, useEffect, useRef } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import Spinner from './components/common/Spinner';
import PrivateRoute from './components/PrivateRoute';
import AdminLayout from './components/layout/AdminLayout';
import LoginCallback from './auth/loginCallback';

// Lazy loaded pages
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const RegisterPage = lazy(() => import('./pages/RegisterPage'));
const ConfirmEmailPage = lazy(() => import('./pages/ConfirmEmailPage'));
const AccountPage = lazy(() => import('./pages/AccountPage'));
const HomePage = lazy(() => import('./pages/HomePage'));
const AboutPage = lazy(() => import('./pages/AboutPage'));
const PrivacyPolicy  = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfService  = lazy(() => import('./pages/TermsOfService'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const ActivityBuilderPage = lazy(() => import('./pages/ActivityBuilderPage'));
const ActivityPage = lazy(() => import('./pages/ActivityPage'));
const ActivitiesPage = lazy(() => import('./pages/ActivitiesPage'));
const AddActivityPage = lazy(() => import('./pages/AddActivityPage'));
const EditActivityPage = lazy(() => import('./pages/EditActivityPage'));
const BlogPage = lazy(() => import('./pages/BlogPage'));

const AdminDashboardPage = lazy(() => import('./pages/Admin/AdminDashboardPage'));
const AdminUserManagementPage = lazy(() => import('./pages/Admin/AdminUserManagementPage'));
const AdminActivitiesPage = lazy(() => import('./pages/Admin/AdminActivitiesPage'));
const AdminReportsPage = lazy(() => import('./pages/Admin/AdminReportsPage'));
const AdminSitemapPage = lazy(() => import('./pages/Admin/AdminSitemapPage'));


function RoutesContainer({ setIsAdminRoute }) {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [isLoading, setIsLoading] = useState(false);
  const transitionRef = useRef();
  const [isAdminRoute, setIsAdminRouteLocal] = useState(location.pathname.startsWith('/admin'));

  useEffect(() => {
    if (location !== displayLocation) {
      setIsLoading(true);
      setTimeout(() => {
        setDisplayLocation(location);
        setIsLoading(false);
      }, 300);
    }
  }, [location, displayLocation]);

  useEffect(() => {
    const isAdmin = location.pathname.startsWith('/admin');
    setIsAdminRoute(isAdmin);
    setIsAdminRouteLocal(isAdmin);
  }, [location.pathname, setIsAdminRoute]);

  return (
    <>
      <div ref={transitionRef} className={`fade ${isLoading ? 'fade-enter' : 'fade-enter-active'}`}>
        {isLoading && !isAdminRoute ? (
          <Spinner />
        ) : (
          <Suspense fallback={<Spinner />}>
            <Routes location={displayLocation}>
              <Route path="*" element={<ErrorBoundary><NotFoundPage /></ErrorBoundary>} />
              <Route path="/" element={<ErrorBoundary><ActivitiesPage /></ErrorBoundary>} />
              <Route path="/home" element={<ErrorBoundary><HomePage /></ErrorBoundary>} />
              <Route path="/login" element={<ErrorBoundary><LoginPage /></ErrorBoundary>} />
              <Route path="/login-callback" element={<ErrorBoundary><LoginCallback /></ErrorBoundary>} />
              <Route path="/register" element={<ErrorBoundary><RegisterPage /></ErrorBoundary>} />
              <Route path="/confirm-email" element={<ErrorBoundary><ConfirmEmailPage /></ErrorBoundary>} />
              <Route path="/about" element={<ErrorBoundary><AboutPage /></ErrorBoundary>} />
              <Route path="/contact" element={<ErrorBoundary><ContactPage /></ErrorBoundary>} />
              <Route path="/activity-builder" element={<ErrorBoundary><ActivityBuilderPage /></ErrorBoundary>} />
              <Route path="/activity/:slug" element={<ErrorBoundary><ActivityPage /></ErrorBoundary>} />
              <Route path="/activities" element={<ErrorBoundary><ActivitiesPage /></ErrorBoundary>} />
              <Route path="/privacy" element={<ErrorBoundary><PrivacyPolicy /></ErrorBoundary>} />
              <Route path="/terms" element={<ErrorBoundary><TermsOfService /></ErrorBoundary>} />
              <Route path="/blog" element={<ErrorBoundary><BlogPage /></ErrorBoundary>} />


              <Route element={<PrivateRoute />}>
                <Route path="/account" element={<ErrorBoundary><AccountPage /></ErrorBoundary>} />
              </Route>

              {/* Admin routes */}
              <Route path="/admin" element={<Navigate to="/admin/dashboard" replace />} />

              <Route element={<PrivateRoute />}>
                <Route path="/admin" element={<AdminLayout isLoading={isLoading} />}>
                  <Route path="dashboard" element={<AdminDashboardPage />} />
                  <Route path="activities" element={<AdminActivitiesPage />} />
                  <Route path="activities/addActivity" element={<AddActivityPage />} />
                  <Route path="activities/editActivity/:id" element={<EditActivityPage />} />
                  <Route path="sitemap" element={<AdminSitemapPage />} />
                  <Route path="usermanagement" element={<AdminUserManagementPage />} />
                  <Route path="reports" element={<AdminReportsPage />} />
                </Route>
              </Route>
            </Routes>
          </Suspense>
        )}
      </div>
    </>
  );
}

export default RoutesContainer;
