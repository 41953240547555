import { showToast } from '../components/common/Toast';

const isSerialized = (data) => {
    if (typeof data === 'string') {
        try {
            JSON.parse(data);
            return true;
        } catch (e) {
            return false;
        }
    }
    return false;
};

const handleGlobalError = (message) => {
    showToast.error(message || 'Unable to communicate with the server. Please try again later.');
};

const apiRequest = async (url, options = {}, handleUnauthorized) => {
    let headers = {};

    // Set Content-Type only if the body is not FormData
    if (!(options.body instanceof FormData)) {
        headers['Content-Type'] = 'application/json';
    }

    // Stringify the body if it's not FormData and not already serialized
    if (options.body && !(options.body instanceof FormData) && !isSerialized(options.body)) {
        options.body = JSON.stringify(options.body);
    }

    const fetchOptions = {
        ...options,
        headers: {
            ...headers,
            ...options.headers,
        },
        credentials: 'include', // Ensure credentials are included
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, fetchOptions);

        if (response.status === 401) {
            if (handleUnauthorized) {
                handleUnauthorized();
            }
            return { success: false, error: 'Unauthorized' };
        }

        let data;
        try {
            data = await response.json();
        } catch (err) {
            data = null;
        }

        if (!response.ok) {
            const errorMessage = data && data.message ? data.message : 'Network response was not ok';
            handleGlobalError(errorMessage);
            return { success: false, error: errorMessage };
        }

        return { success: true, data };
    } catch (err) {
        handleGlobalError(err.message || 'Network error');
        return { success: false, error: err.message || 'Network error' };
    }
};

const apiGet = async (url, options = {}, handleUnauthorized) => apiRequest(url, { ...options, method: 'GET' }, handleUnauthorized);
const apiPost = async (url, data, options = {}, handleUnauthorized) => apiRequest(url, { ...options, method: 'POST', body: data }, handleUnauthorized);
const apiPut = async (url, data, options = {}, handleUnauthorized) => apiRequest(url, { ...options, method: 'PUT', body: data }, handleUnauthorized);
const apiDelete = async (url, options = {}, handleUnauthorized) => apiRequest(url, { ...options, method: 'DELETE' }, handleUnauthorized);

export { apiGet, apiPost, apiPut, apiDelete };
