import React, { useState, useEffect, Fragment } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../auth/authContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBell, faXmark, faSignInAlt, faFamily } from '@fortawesome/pro-solid-svg-icons';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import LogoComponent from '../svgs/logo';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function NavBar({ onSearch }) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { user, roles, signout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (onSearch) {
      onSearch(e.target.value);
    }
  };

  const handleLogout = () => {
    signout(() => navigate("/"));
  };

  const isActive = (path) => location.pathname === path;

  const isAdmin = roles.includes('Admin');

  return (
    <Disclosure as="nav" className={`navbar transition duration-300 ease-in-out ${isScrolled ? 'shadow-lg' : ''}`}>
      {({ open }) => (
        <>
          <div className="container mx-auto px-6 md:px-12">
            <div className="flex items-center justify-between py-3">
              <div className="flex items-center">
                <Link to="/" className="flex items-center">
                  <LogoComponent className="h-16 w-auto" />
                  <h1 className='font-bold font text-xl ml-4 hidden md:block'>Kids Activity Ideas</h1>
                </Link>
                <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                  <Link
                    to="/"
                    className={classNames(
                      'inline-flex items-center px-1 pt-1 text-sm font-medium',
                      isActive('/') ? 'border-b-2 border-carrot-orange-500 text-white' : 'border-transparent text-white hover:border-carrot-orange-500 hover:text-white'
                    )}
                  >
                    Activities
                  </Link>
                  <Link
                    to="/about"
                    className={classNames(
                      'inline-flex items-center px-1 pt-1 text-sm font-medium',
                      isActive('/about') ? 'border-b-2 border-carrot-orange-500 text-white' : 'border-transparent text-white hover:border-carrot-orange-500 hover:text-white'
                    )}
                  >
                    About
                  </Link>
                  <Link
                    to="/contact"
                    className={classNames(
                      'inline-flex items-center px-1 pt-1 text-sm font-medium',
                      isActive('/contact') ? 'border-b-2 border-carrot-orange-500 text-white' : 'border-transparent text-white hover:border-carrot-orange-500 hover:text-white'
                    )}
                  >
                    Contact
                  </Link>
                </div>
              </div>
              {/*
              <div className="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">
                <div className="w-full max-w-lg lg:max-w-xs">
                  <label htmlFor="search" className="sr-only">Search</label>
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <FontAwesomeIcon icon={faMagnifyingGlass} className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                      id="search"
                      name="search"
                      className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                      placeholder="Search"
                      type="search"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
              </div>
               */}
              <div className="flex items-center lg:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <FontAwesomeIcon icon={faXmark} className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <FontAwesomeIcon icon={faBars} className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="hidden lg:ml-4 lg:flex lg:items-center">
                <button
                  type="button"
                  className="relative text-white p-1 hover:text-gray-400 focus:outline-none"
                >
                  <span className="sr-only">View notifications</span>
                  <FontAwesomeIcon icon={faBell} className="h-6 w-6" aria-hidden="true" />
                </button>
                {user ? (
                  <Menu as="div" className="relative ml-4 flex-shrink-0">
                    <div>
                      <Menu.Button className="relative text-white p-1 hover:text-gray-400 focus:outline-none">
                        <span className="sr-only">Open user menu</span>
                        {user.avatarUrl ? (
                          <img
                            src={user.avatarUrl}
                            alt="User Avatar"
                            className="h-8 w-8 rounded-full"
                          />
                        ) : (
                          <FontAwesomeIcon icon={faFamily} className="block h-6 w-6" aria-hidden="true" />
                        )}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {isAdmin && (
                          <Menu.Item>
                            {({ active }) => (
                              <Link to="/admin" className={classNames(active ? 'bg-gray-100' : '', 'block w-full text-left px-4 py-2 text-sm text-gray-700')}>
                                Admin Menu
                              </Link>
                            )}
                          </Menu.Item>
                        )}
                        <Menu.Item>
                          {({ active }) => (
                            <Link to="/account" className={classNames(active ? 'bg-gray-100' : '', 'block w-full text-left px-4 py-2 text-sm text-gray-700')}>
                              Your Profile
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={handleLogout}
                              className={classNames(active ? 'bg-gray-100' : '', 'block w-full text-left px-4 py-2 text-sm text-gray-700')}
                            >
                              Sign out
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <Link to="/login" className="ml-6 text-white py-2 px-4 transition duration-300 font-bold">
                    <FontAwesomeIcon icon={faSignInAlt} className="h-6 w-6" />
                  </Link>
                )}
              </div>
            </div>
          </div>
          <Disclosure.Panel className="lg:hidden  bg-atoll-700">
            <div className="space-y-1 pb-3 pt-2">
              <Disclosure.Button
                as={Link}
                to="/"
                className={classNames(
                  'block border-l-4 py-2 pl-3 pr-4 text-base font-medium text-white',
                  isActive('/') ? 'border-carrot-orange-500 bg-atoll-700' : 'border-transparent hover:border-carrot-orange-500 hover:bg-atoll-700'
                )}
              >
                Activities
              </Disclosure.Button>
              <Disclosure.Button
                as={Link}
                to="/about"
                className={classNames(
                  'block border-l-4 py-2 pl-3 pr-4 text-base font-medium text-white',
                  isActive('/about') ? 'border-carrot-orange-500 bg-atoll-700' : 'border-transparent hover:border-carrot-orange-500 hover:bg-atoll-700'
                )}
              >
                About
              </Disclosure.Button>
              <Disclosure.Button
                as={Link}
                to="/contact"
                className={classNames(
                  'block border-l-4 py-2 pl-3 pr-4 text-base font-medium text-white',
                  isActive('/contact') ? 'border-carrot-orange-500 bg-atoll-700' : 'border-transparent hover:border-carrot-orange-500 hover:bg-atoll-700'
                )}
              >
                Contact
              </Disclosure.Button>
            </div>
            <div className="border-t border-gray-200 pb-3 pt-4">
              {user ? (
                <div>
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0 text-white">
                      {user.avatarUrl ? (
                        <img
                          src={user.avatarUrl}
                          alt="User Avatar"
                          className="h-8 w-8 rounded-full"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faFamily} className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </div>
                    <div className="ml-3">
                      <div className="text-sm font-medium text-white">{user.userName}</div>
                    </div>
                    <button
                      type="button"
                      className="ml-auto flex-shrink-0 rounded-full p-1 text-white hover:text-gray-500"
                    >
                      <span className="sr-only">View notifications</span>
                      <FontAwesomeIcon icon={faBell} className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1">
                    <Disclosure.Button
                      as={Link}
                      to="/account"
                      className={classNames(
                        'block border-l-4 py-2 pl-3 pr-4 text-base font-medium text-white',
                        isActive('/account') ? 'border-carrot-orange-500 bg-atoll-700' : 'border-transparent hover:border-carrot-orange-500 hover:bg-atoll-700'
                      )}
                    >
                      Your Profile
                    </Disclosure.Button>
                    {isAdmin && (
                      <Disclosure.Button
                        as={Link}
                        to="/admin"
                        className={classNames(
                          'block border-l-4 py-2 pl-3 pr-4 text-base font-medium text-white',
                          isActive('/admin') ? 'border-carrot-orange-500 bg-atoll-700' : 'border-transparent hover:border-carrot-orange-500 hover:bg-atoll-700'
                        )}
                      >
                        Admin Menu
                      </Disclosure.Button>
                    )}
                    <Disclosure.Button
                      as="button"
                      onClick={handleLogout}
                      className='block border-l-4 py-2 pl-3 pr-4 text-base font-medium text-white border-transparent hover:border-carrot-orange-500 hover:bg-atoll-700'
                    >
                      Sign out
                    </Disclosure.Button>
                  </div>
                </div>
              ) : (
                <div className="flex items-center px-4">
                  <Link
                    to="/login"
                    className="block w-full text-center px-4 py-2 text-base font-medium text-white hover:bg-gray-100 hover:text-gray-800"
                  >
                    Sign In
                  </Link>
                </div>
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default NavBar;
