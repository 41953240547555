import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-atoll-700 text-white">
            <div className="max-w-6xl mx-auto px-4 py-10 md:py-20">
                <div className="md:flex md:justify-between">
                    <div className="mb-6 md:mb-0">
                        <a href="/" className="flex items-center">
                            <span className="text-2xl font-bold">Kids Activity Ideas</span>
                        </a>
                        <p className="mt-2 text-sm">© 2024 Kids Activity Ideas. All rights reserved.</p>
                    </div> 
                    <div className="grid grid-cols-2 gap-8 sm:gap-16 sm:grid-cols-4">
                        <div>
                            <h2 className="font-bold mb-2">Company</h2>
                            <ul>
                                <li><a href="/about" className="hover:underline">About Us</a></li>
                                <li><a href="/contact" className="hover:underline">Contact</a></li>
                                <li><a href="/privacy" className="hover:underline">Privacy Policy</a></li>
                                <li><a href="/terms" className="hover:underline">Terms of Service</a></li>
                            </ul>
                        </div>
                        <div>
                            <h2 className="font-bold mb-2">Resources</h2>
                            <ul>
                                <li><a href="/blog" className="hover:underline">Blog</a></li>
                                {/* Additional links can be added here */}
                            </ul>
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <h2 className="font-bold mb-2">Follow Us</h2>
                            <div className="flex items-center space-x-6">
                                <a href="https://twitter.com" className="hover:opacity-75">
                                    <i className="fab fa-twitter fa-lg"></i>
                                </a>
                                {/* Additional social links can be added here */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
