import { apiPost } from './apiClient';

export async function loginUser(credentials) {
    try {
        const response = await apiPost('/account/login', credentials);
        if (!response.success) {
            console.error('Login failed:', response.message);
            return { success: false, message: response.message || 'Login failed' };
        }
        return { success: true, message: 'Login successful', user: response.data.user };
    } catch (error) {
        console.error('Login error:', error);
        return { success: false, message: 'Network error' };
    }
}

export async function logoutUser() {
    try {
        const response = await apiPost('/account/logout');
        if (response.success) {
            
            // Clear localStorage items
            localStorage.removeItem('user');
            localStorage.removeItem('roles');

            // Clear cookies
            document.cookie = 'auth_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            document.cookie = '.AspNetCore.Identity.Application=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

            return { success: true, message: 'Logout successful' };
        } else {
            console.error('Logout failed:', response.message);
            return { success: false, message: response.message || 'Logout failed' };
        }
    } catch (error) {
        console.error('Logout error:', error);
        return { success: false, message: 'Network error' };
    }
}
