import { Toaster, toast } from 'react-hot-toast';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';

const Toast = () => {
  return (
    <Toaster
      position="top-right"
      toastOptions={{
        duration: 4000,
        style: {
          background: '#fff',
          color: '#000',
        },
      }}
    />
  );
};

export default Toast;


const CustomToast = ({ message, type, onClose }) => (
  <div className="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5">
    <div className="flex-shrink-0 p-4 flex items-center">
      <FontAwesomeIcon
        icon={type === 'success' ? faCheckCircle : faTimesCircle}
        className={type === 'success' ? 'text-green-500 w-10 h-10' : 'text-red-500 w-10 h-10'}
      />
    </div>
    
    <div className="flex-1 p-4 flex items-center">
      <p className="text-sm text-gray-500">{message}</p>
    </div>

    <div className="flex items-center border-l border-gray-200">
      <button
        onClick={onClose}
        className="p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
      >
        Close
      </button>
    </div>
  </div>
);

export const showToast = {
  success: (message) =>
    toast.custom((t) => (
      <CustomToast message={message} type="success" onClose={() => toast.remove(t.id)} />
    )),
  error: (message) =>
    toast.custom((t) => (
      <CustomToast message={message} type="error" onClose={() => toast.remove(t.id)} />
    )),
};
