import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/authContext';

const LoginCallback = () => {
    const navigate = useNavigate();
    const { signin } = useAuth();

    useEffect(() => {
        // Fetch user details from the server
        fetch(`${process.env.REACT_APP_API_URL}/user/details`, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(user => {
            if (user) {
                signin(user, () => navigate('/')); // Set user and redirect
            } else {
                console.error('No user data found');
                navigate('/login'); // Redirect to login if no user data found
            }
        })
        .catch(error => {
            console.error('Error fetching user details:', error);
            navigate('/login');
        });
    }, [navigate, signin]);

    return <div>Loading...</div>;
};

export default LoginCallback;
