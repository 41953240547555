import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import Toast from './components/common/Toast';
import { AuthProvider } from './auth/authContext';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import RoutesContainer from './RoutesContainer';
import ReactGA from 'react-ga';

// Google Analytics initialization
ReactGA.initialize('G-0HX758TT92');

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null;
};

function App() {
  const [isAdminRoute, setIsAdminRoute] = useState(false);

  return (
    <Router>
      <AuthProvider>
        <div className="app-container flex flex-col min-h-screen">
          <TrackPageView />
          <Toast />
          {!isAdminRoute && <Navbar />}
          <div className="flex-grow relative content-view">
            <RoutesContainer setIsAdminRoute={setIsAdminRoute} />
          </div>
          {!isAdminRoute && <Footer />}
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
