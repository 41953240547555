import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser, logoutUser } from '../services/authService';
import { apiGet } from '../services/apiClient';

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const [authError, setAuthError] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const initAuth = async () => {
            if (user) {
                try {
                    const response = await apiGet('/user/details', {}, handleSessionExpiration);
                    if (!response.success) {
                        handleSessionExpiration();
                    }
                } catch {
                    handleSessionExpiration();
                }
            }
            setLoading(false);
        };
        initAuth();
    }, []);

    useEffect(() => {
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
            if (user.roles) {
                localStorage.setItem('roles', JSON.stringify(user.roles));
            }
        } else {
            localStorage.removeItem('user');
            localStorage.removeItem('roles');
        }
    }, [user]);

    const handleSessionExpiration = () => {
        setUser(null);
        setAuthError('Session expired. Please log in again.');
        localStorage.removeItem('user');
        localStorage.removeItem('roles');
        navigate('/login');
    };

    const signin = (newUser, callback) => {
        setUser(newUser);
        if (newUser.roles) {
            localStorage.setItem('roles', JSON.stringify(newUser.roles));
        }
        if (callback) callback();
    };

    const signout = async (callback) => {
        const response = await logoutUser();
        if (response.success) {
            setUser(null);
            localStorage.removeItem('user');
            localStorage.removeItem('roles');
            if (callback) callback();
            navigate('/');
        } else {
            setAuthError(response.message);
        }
    };

    const login = async (credentials) => {
        try {
            const response = await loginUser(credentials);
            if (response.success) {
                signin({ ...response.user, roles: response.user.roles });
                setAuthError(null);
                return response;
            } else {
                setAuthError(response.message);
                return response;
            }
        } catch (error) {
            console.error('Error during login:', error);
            setAuthError('Network error');
            return { success: false, message: 'Network error' };
        }
    };

    return (
        <AuthContext.Provider value={{ user, roles: user?.roles || [], signin, signout, login, authError, loading }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
